package com.dragselectcompose.grid.indicator.internal

import androidx.compose.material.icons.Icons
import androidx.compose.material.icons.materialIcon
import androidx.compose.material.icons.materialPath
import androidx.compose.ui.graphics.vector.ImageVector

/**
 * Copy of material-icons-extended.
 */
@Suppress("UnusedReceiverParameter")
public val Icons.Filled.RadioButtonUnchecked: ImageVector
    get() {
        if (_radioButtonUnchecked != null) {
            return _radioButtonUnchecked!!
        }
        _radioButtonUnchecked = materialIcon(name = "Filled.RadioButtonUnchecked") {
            materialPath {
                moveTo(12.0f, 2.0f)
                curveTo(6.48f, 2.0f, 2.0f, 6.48f, 2.0f, 12.0f)
                reflectiveCurveToRelative(4.48f, 10.0f, 10.0f, 10.0f)
                reflectiveCurveToRelative(10.0f, -4.48f, 10.0f, -10.0f)
                reflectiveCurveTo(17.52f, 2.0f, 12.0f, 2.0f)
                close()
                moveTo(12.0f, 20.0f)
                curveToRelative(-4.42f, 0.0f, -8.0f, -3.58f, -8.0f, -8.0f)
                reflectiveCurveToRelative(3.58f, -8.0f, 8.0f, -8.0f)
                reflectiveCurveToRelative(8.0f, 3.58f, 8.0f, 8.0f)
                reflectiveCurveToRelative(-3.58f, 8.0f, -8.0f, 8.0f)
                close()
            }
        }
        return _radioButtonUnchecked!!
    }

@Suppress("ObjectPropertyName")
private var _radioButtonUnchecked: ImageVector? = null
